import React, { useState } from "react";
import style from "./PortfolioWebsites.module.css";
import NavBar from "../../Component/NavBar/NavBar";
import img from "../../Image/Portfolio Websites 1.png";
import Section2 from "./Section2/Section2";
import Section3 from "./Section3/Section3";
import SixthSection from "../../Section/SixthSection/SixthSection";
import EleventhSection from "../../Section/EleventhSection/EleventhSection";
import Footer from "../../Component/Footer/Footer";
import CallButton from "../../Component/CallButton/CallButton";
import PopupOption from "../../Component/PopupOption/PopupOption";
import Section4 from "./Section4/Section4";
import ForthSection from "../../Section/ForthSection/ForthSection";
import ServiceForm from "../../Component/ServiceForm/ServiceForm";
import Section5 from "./Section5/Section5";
import Section from "./Section1/Section";

function PortfolioWebsites() {
  const [showPopup, setShowPopup] = useState(false);

  //popup
  const handleGetStartedClick = () => {
    setShowPopup(true);
  };
  return (
    <div className={style.main}>
      <NavBar />
      <div className={style.container}>
        <div>
          <h1>WordPress Portfolio Website Development Services</h1>
          <h6>
            Display Your Skills with Professional WordPress Portfolio Website
            Development Services
          </h6>
          <p>
            Welcome to EasyRyt’s specialized services for developing expert
            WordPress portfolio websites. We bring together innovation, finesse
            in design, and technical proficiency to create captivating online
            portfolios that highlight your distinctive abilities. With our
            extensive knowledge of WordPress and its capabilities, we design
            websites that not only showcase your work but also make a lasting
            impact on your visitors.
          </p>
          <button
            type="submit"
            className={style.submitBtn}
            onClick={handleGetStartedClick}
          >
            Get Started
          </button>
        </div>
        <div className={style.img_box}>
          <img className={style.img} src={img} alt="WordPress Portfolio Website Development Services" />
        </div>
      </div>
      <Section />
      <Section2 />
      <Section3 />
      <Section4 />
      <ServiceForm />
      <ForthSection />
      <SixthSection />
      <EleventhSection />
      <Section5 />
      <CallButton />
      <Footer />
      {showPopup && (
        <>
          <button
            onClick={() => setShowPopup(false)}
            className={style.closebtn}
          >
            Close
          </button>
          <PopupOption />
        </>
      )}
    </div>
  );
}

export default PortfolioWebsites;
