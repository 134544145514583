import React, { useRef, useEffect } from "react";
import style from "./Section.module.css";
import img from "../../../Image/have-a-great-idea-for-an-app.png";
import { gsap } from "gsap";
import { useIntersection } from "react-use";

function Section() {
  const sectionRef = useRef(null);
  const intersection = useIntersection(sectionRef, {
    root: null,
    rootMargin: "0px",
    threshold: 0.2,
  });

  useEffect(() => {
    const fadeInTimeline = gsap.timeline({ paused: true });
    fadeInTimeline.to("#header", {
      opacity: 1,
      y: -60,
      ease: "power4.out",
      duration: 1,
    });

    const fadeOutTimeline = gsap.timeline({ paused: true });
    fadeOutTimeline.to("#header", {
      opacity: 0,
      y: -50,
      ease: "power4.out",
      duration: 1,
    });

    if (intersection && intersection.intersectionRatio < 0.2) {
      fadeOutTimeline.play();
    } else {
      fadeInTimeline.play();
    }
  }, [intersection]);

  return (
    <div className={style.main}>
      <div>
        <img
          id="header"
          ref={sectionRef}
          className={style.img}
          src={img}
          alt="img"
        />
      </div>

      <div>
        <h2 id="header" ref={sectionRef}>
          The Importance of WordPress Blog and Personal Websites
        </h2>
        <p className={style.para} id="header" ref={sectionRef}>
          In this perpetually evolving digital realm, your online presence
          serves as your unique electronic signature. Whether you are
          disseminating your ideas via a blog or exhibiting your portfolio,
          possessing a website that profoundly resonates with your audience
          proves vital. WordPress presents an intuitively navigable platform
          that empowers you to effortlessly fabricate, administer, and refresh
          your content, even in the absence of technical prowess.
        </p>
      </div>
    </div>
  );
}

export default Section;
