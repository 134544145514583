import React, { useRef, useEffect } from "react";
import style from "./Section.module.css";
import img from "../../../Image/have-a-great-idea-for-an-app.png";
import { gsap } from "gsap";
import { useIntersection } from "react-use";

function Section() {
  const sectionRef = useRef(null);
  const intersection = useIntersection(sectionRef, {
    root: null,
    rootMargin: "0px",
    threshold: 0.2,
  });

  useEffect(() => {
    const fadeInTimeline = gsap.timeline({ paused: true });
    fadeInTimeline.to("#header", {
      opacity: 1,
      y: -60,
      ease: "power4.out",
      duration: 1,
    });

    const fadeOutTimeline = gsap.timeline({ paused: true });
    fadeOutTimeline.to("#header", {
      opacity: 0,
      y: -50,
      ease: "power4.out",
      duration: 1,
    });

    if (intersection && intersection.intersectionRatio < 0.2) {
      fadeOutTimeline.play();
    } else {
      fadeInTimeline.play();
    }
  }, [intersection]);

  return (
    <div className={style.main}>
      <div>
        <img
          id="header"
          ref={sectionRef}
          className={style.img}
          src={img}
          alt="Facebook & Instagram Advertising"
        />
      </div>

      <div>
        <h2 id="header" ref={sectionRef}>
          Why Select Application Backend Creation?
        </h2>
        <p className={style.para} id="header" ref={sectionRef}>
          Application backend creation serves as the foundation of your
          software, responsible for managing information, user verification,
          server communication, and beyond. It represents the concealed power
          that guarantees your application operates seamlessly and provides a
          flawless encounter for your users.
        </p>
      </div>
    </div>
  );
}

export default Section;
